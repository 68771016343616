@import 'variables', 'mixins';

header{
  background: $color-primary;
  padding: $gutter 0;
  position: sticky;
  top:0;
  z-index: 9;
}

.optionsContainer{
  position: sticky;
  top:65px;
  background-color: #FFF;
  padding: $gutter 0;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
  z-index: 9;
  margin-bottom: $gutter*2;
  .lettersContainer{
    margin: 0 -5px;
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    overflow: auto;
    @include sm-up{
      flex-wrap: wrap;
    }
    button{
      background-color: #f5f5f5;
      border-radius: 6px;
      width: calc(100%/26 - 10px);
      height: 35px;
      font-size: 16px;
      text-transform: uppercase;
      border: 0 none;
      margin: 5px;
      &:not(:disabled){
        cursor: pointer;
        color: $color-primary;
        @include addEase();
        display: block;
        &:hover{
          background-color: #e9e9e9;
        }
      }
      @include lg-down{
        width: calc(100%/13 - 10px);
      }
      @include sm-down{
        display: none;
        min-width: 35px
      }
    }
  }
}

.banner{
  background: url('../images/bg-banner.png') no-repeat top center;
  background-size: cover;
  padding: 170px 0 140px;
 
  h1{
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    margin: 0;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    padding-left: 70px;
    @include fontSize(72);
  }
  @include md-down{
    padding: 70px 0 40px;
    h1{
      padding: 0;
      @include fontSize(52);
    }
  }
  @include sm-down{
    padding: 30px 0 20px;
    h1{
      @include fontSize(24);
    }
  }
}