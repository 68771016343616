@import 'variables','reset';

html{        
  font-size:16px; 
}  
body{
  position: relative;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  color: #666;
  background: url('../images/linhas.png') #fff center 95px repeat-y;
  // background: #fff;
}

.container{
  width: 100%;
  max-width: 1200px;
  padding: 0 $gutter;
  margin:0 auto;
}